<template>
  <div class="contents">

    <InfoBlocks/>

    <div class="flex flex-row justify-between w-full text-lg mb-2 uppercase mt-8">
      <div class="text-xs ">Актуальні збори</div>
    </div>

    <div class="grid gap-4 grid-cols-1 md:grid-cols-2">
      <div class="jar bg-slate-200 rounded-xl shadow  p-4 mt-2 mb-2" v-for="jar in jars" :key="jar.id">
        <router-link :to="{name: 'jar', params: {jar: jar.public_key}}">
          <div class="w-full space-x-2 grid grid-cols-12">
            <div class="col-span-12 sm:col-span-12 h-full justify-between flex flex-col">
              <div class="flex-grow">
                <div class="flex flex-row justify-between">
                  <p class="font-bold text-xl">{{ jar.name }}</p>
                  <p class="font-bold text-xs items-center">Перейти &rarr;</p>
                </div>
                <p v-html="jar.html" class="whitespace-nowrap">
                </p>
              </div>
              <div class="mt-4">
                <p class="text-sm mb-2">Зібрано <b>{{ formatCurrency(jar.amount_current/100) }}</b> з {{
                    formatCurrency(jar.amount_required / 100)
                  }}</p>

                <div class="w-full bg-gray-500 dark:bg-gray-700">
                  <div class="bg-green-500 text-xs font-medium text-white text-center p-0.5 leading-none"
                       :style="{width: ((jar.amount_current >= jar.amount_required ? jar.amount_required : jar.amount_current) * 100 / jar.amount_required)+'%'}"
                  > {{ (jar.amount_current * 100 / jar.amount_required).toFixed(3) }}%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

import InfoBlocks from "@/views/jar/InfoBlocks.vue";
import {formatCurrency} from "../helpers";

export default {
  methods: {formatCurrency},
  components: {InfoBlocks},
  data() {
    return {
      jars: []
    }
  },
  created() {
    this.$http.get('/api/jars').then(({data}) => {
      this.jars = data.data
    })
  },
}
</script>

<style>

</style>
